export const BattlesColors = {
  MAIN: ['#CD6200', '#D40638'],
  MAIN_LIGHT: ['#FF8A00', '#FF354D'],
  BOT_MAIN: ['#39A0FF', '#39E7FF'],
  BOT_BLUE: ['#1CA4F0'],
  BOT_BLUE_DARK: ['#0065C1'],
  BOT_BLUE_LIGHT: ['#3CE8FF'],
  EVENT: ['#FF4853', '#FFEB37'],
  END_BATTLE_7: 'rgba(139 140 150 / 7%)',
  END_BATTLE_15: 'rgba(139 140 150 / 15%)',
  WIN: 'rgb(41 243 182)',
  WIN_15: 'rgb(41 243 182 / 15%)',
  WIN_7: 'rgb(41 243 182 / 7%)',
  WIN_30: 'rgb(41 243 182 / 30%)',
  LOSE: 'rgb(227 34 99)',
  LOSE_7: 'rgb(227 34 99 / 7%)',
  LOSE_15: 'rgb(227 34 99 / 15%)',
  LOSE_30: 'rgb(227 34 99 / 30%)',
  MAIN_SHADOW: '#DC68D0',
  BOT_SHADOW: 'rgb(60 232 255 / 15%)',
  WAIT: '#66656F26',
  READY_WITH_PLAYERS: 'rgb(255 134 4)',
  READY_WITH_PLAYERS_15: 'rgb(255 134 4 / 15%)',
  READY_WITH_PLAYERS_30: 'rgb(255 134 4 / 30%)',
  READY_WITH_BOTS: 'rgb(60 232 255)',
  READY_WITH_BOTS_15: 'rgb(60 232 255 / 15%)',
  READY_WITH_BOTS_30: 'rgb(60 232 255 / 30%)',
};
